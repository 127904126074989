<template>
  <v-card
    tile
    flat
    class="data-tabs px-3"
  >
    <v-toolbar 
      width="100%"
      height="112"
      max-height="140"
      color="transparent"
      flat
      class="data-tabs-toolbar"
    >
      <v-tabs
        v-model="tab"
        background-color="transparent"
        slider-size="2"
        fixed-tabs
        class="ml-0 mr-auto"
      >
        <v-tab
          v-for="(tab,k) in tabs"
          :key="k"
          active-class="active"
          class="align-start justify-start text-left pa-4"
          :href="'#' + k"
          @change="onTabChange(k)"
        >
          <h2 class="title subtitle-1 font-weight-bold text-no-wrap">
            {{ $t(tab.title) }}
            <!-- <v-tooltip 
              v-if="tab.number.hint!=null"
              top
              open-delay="200"
              transition="slide-y-reverse-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="grey lighten-1"
                  small
                  class="ml-1 mb-1"
                >
                  {{ icons.help }}
                </v-icon>
              </template>
              <span>{{ tab.number.hint }}</span>
            </v-tooltip> -->
          </h2>
          <h4 class="value text-h5 mt-2">
            {{ formatNumber(tab.total.value, tab.total.format) }}
          </h4>
          <v-progress-linear
            :active="tab.loading"
            absolute
            bottom
            indeterminate
            height="2"
            :color="metric == k ? 'white' : 'accent'"
            class="loading"
          />
          <!-- <span 
            v-if="tab.loading&&tab.number.secondary.data!=null"
            :class="[ tab.number.secondary.colored ? secondaryColor(tab.number.secondary) : 'grey--text text--darken-1' ]"
            class="secondary-number body-1 font-weight-medium mt-2"
          >
            <v-icon
              v-if="tab.number.secondary.colored"
              small
              :class="[ tab.number.secondary.colored ? secondaryColor(tab.number.secondary) : 'grey--text text--darken-1' ]"
              style="margin-top: -2px !important;"
            >
              {{ secondaryIcon(tab.number.secondary) }}
            </v-icon>
            {{ $numeral(tab.number.secondary.data).format(tab.number.secondary.format) }}
          </span> -->
        </v-tab>
      </v-tabs>
      <v-divider class="divider" />
    </v-toolbar>
  </v-card>
</template>

<style>

  .data-tabs {
    /* position: sticky;
    top: 56px; */
    z-index: 2;
    background: #FBFBFD !important;
  }
  @media (min-width: 960px) {
    .data-tabs {
      /* top: 64px; */
    }
  }

  .v-toolbar__content {
    max-height: initial;
  }

  .data-tabs .v-tabs .v-tab--active:hover::before {
    opacity: 0.04;
  }

  .data-tabs-toolbar .divider {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .data-card .title, .data-card .numbers .title, .data-card .value {
    color: var(--mobees-black);
  }

  .data-card .secondary-number {
    font-size: .875em !important;
  }

  .data-card .placeholder.loading {
    animation: loading 2s infinite ease-in-out;
  }
  .data-card .placeholder {
  }

  .data-tabs .v-tabs-slider-wrapper {
    /* top: 0; */
  }
  .data-tabs .v-tab {
    text-transform: initial;
    flex-direction: column;
  }
  .data-tabs .v-tab.active .loading {
    /* bottom: 2px !important; */
    opacity: .6;
  }
  .data-tabs .v-tab.active .value {
    font-weight: 900 !important;
  }
  .data-tabs .v-tab .value {
    font-size: 1.5rem;
  }

  .data-tabs .v-window-item.v-window-item--active {
    width: 100% !important;
  }

  .data-tabs .chart > div, .data-tabs .chart > div > div {
    /* width: 100% !important; */
  }

  @keyframes loading {
    0% {
      opacity: .4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: .4;
    }
  }
  
</style>

<script>
  import services from '@/services'
  import { mdiHelpCircleOutline, mdiArrowUp, mdiArrowDown } from '@mdi/js'

  export default {

    components: {
      
    },

    props: {
      tabs: {
        type: Object,
        default: () => null,
      },
      metric: {
        type: String,
        default: 'audience',
      },
    },

    data: () => ({
      icons: {
        help: mdiHelpCircleOutline,
        up: mdiArrowUp,
        down: mdiArrowDown,
      },
      ready: false,
      tab: null,
    }),

    watch: {
      tabs: {
        immediate: true,
        deep: true,
        handler (value) {
          // console.log('tabs', value, _.keys(value)[0]);
          // this.tab = _.keys(value)[0];
          this.ready = true;
        }
      },
      metric: {
        immediate: true,
        deep: true,
        handler (value) {
          this.tab = value;
        }
      },
    },

    computed: {
      filterTabs () {
        console.log(this.breakpoint(null, 'xs') ? this.tabs[_.keys(this.tabs)[0]] : this.tabs)
        return this.breakpoint(null, 'xs') ? this.tabs[_.keys(this.tabs)[0]] : this.tabs;
      },
      item () {
        console.log('item', this.tab);
        return this.tabs[this.tab];
      }
      
    },

    methods: {
      ...services,

      onTabChange (tab) {
        console.log(tab, this.tab);
        this.$emit('metric-change', tab);
      },

      onChartReady (chart, google) {
        // console.log(google);
      },

      formatNumber (value, format) {
        const formatted = this.$numeral(value).format(format);
        return value==null ? '-' : formatted.indexOf(':')>=0 && formatted.length>=8 ? this.$numeral(value/60/60).format('0,0[.]0') + ' h' : formatted;
      },

      secondaryIcon (item) {
        return item.data <= 0 ? this.icons.down : this.icons.up;
      },

      secondaryColor (item) {
        item.inverted = _.isNil(item.inverted) ? false : item.inverted;
        const direction = item.inverted ? item.data >= 0 : item.data <= 0;
        return direction ? 'error--text' : 'success--text';
      },
    },

    mounted () {
    }
  }
</script>